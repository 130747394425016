import {Inject, Injectable, Optional} from '@angular/core';
import {MAT_DATE_LOCALE, NativeDateAdapter} from '@angular/material/core';
import moment from "moment-timezone";

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {

  override getFirstDayOfWeek(): number {
    return 1;
  }

  override getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
    return ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'];
  }

  timeZone = 'Europe/Moscow'; // Set your desired time zone

  setTimeZone(timeZone: string) {
    this.timeZone = timeZone;
  }

  private toLocalDate(date: Date): moment.Moment {
    return moment(date).tz(this.timeZone);
  }

  private toDate(date: moment.Moment): Date {
    return date.toDate();
  }

  override parse(value: any): Date | null {
    if (value instanceof Date) {
      return value;
    }
    return this.toDate(moment(value));
  }

  override format(date: Date, displayFormat: Object): string {
    return this.toLocalDate(date).format('L'); // Adjust the format as needed
  }

  override getYear(date: Date): number {
    return this.toLocalDate(date).year();
  }

  override getMonth(date: Date): number {
    return this.toLocalDate(date).month();
  }

  override getDate(date: Date): number {
    return this.toLocalDate(date).date();
  }

  override getDayOfWeek(date: Date): number {
    return this.toLocalDate(date).day();
  }
}
