import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from "@angular/router";


@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  meta = localStorage.getItem(('adminMeta'));

  constructor(private _router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._isLoggedIn(state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._isLoggedIn(state);
  }

  private _isLoggedIn(state: RouterStateSnapshot): boolean {
    this.meta = localStorage.getItem(('adminMeta'));
    if (this.meta) {
      const meta = JSON.parse(this.meta);
      if (meta?.accessToken && meta?.refreshToken) {
        return true;
      }
    } else {
      localStorage.clear();
      this._router.navigate(['/auth']);
    }

    return false;
  }
}
