import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {Observable, of, switchMap} from 'rxjs';
import {AdminUsersService} from "./admin/admin-users.service";
import {NgxPermissionsService} from "ngx-permissions";

@Injectable({
  providedIn: 'root'
})
export class PermissionsResolver implements Resolve<boolean> {

  constructor(
    private adminUsersService: AdminUsersService,
    private permissionsService: NgxPermissionsService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.adminUsersService.getAdminInfo()
      .pipe(
        switchMap(res => {
          this.permissionsService.loadPermissions(res.data.permissions);
          localStorage.setItem('permissions', JSON.stringify(res.data.permissions));
          return of(true);
        })
      )
  }
}
