import {
  AfterViewInit,
  Component,
  ElementRef, EventEmitter,
  Input,
  OnChanges, Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {CommonModule} from "@angular/common";
import {UiIconComponent} from "../ui-icon/ui-icon.component";

@Component({
  selector: 'ui-link',
  templateUrl: './ui-link.component.html',
  styleUrls: ['./ui-link.component.scss'],
  standalone: true,
  imports: [CommonModule, UiIconComponent]
})
export class UiLinkComponent implements OnChanges, AfterViewInit {
  @Input() size: 'medium' | 'base' | 'small' | string = 'medium';
  @Input() color: string = 'makktina-2-600';
  @Input() leftIcon!: string;
  @Input() leftIconType: 'solid' | 'outline' | 'mini' = 'mini';
  @Input() rightIcon!: string;
  @Input() rightIconType: 'solid' | 'outline' | 'mini' = 'mini';
  @Output() onWheel = new EventEmitter<any>();
  @ViewChild('elemRef', {static: false}) elemRef: ElementRef;
  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setColor();
  }

  ngAfterViewInit(): void {
    this.setColor()
    this.elemRef.nativeElement?.addEventListener("mousedown", (event: any) => {
      event.preventDefault();
      if (event.which === 2) {
        this.onWheel.emit(event);
      }
    });
  }
  setColor() {
    if (this.color && this.elemRef) {
      this.elemRef.nativeElement.style.color = `var(--${this.color})`
    }
  }

}
