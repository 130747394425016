import {Component} from '@angular/core';
import {NgxPermissionsService} from "ngx-permissions";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'mk';

  constructor(
    private permissionService: NgxPermissionsService,
  ) {
  }
  ngOnInit(): void {
    this.setPermissions();
  }
  private setPermissions(): void {
    const permissions = JSON.parse(localStorage.getItem('permissions'));
    if (permissions) {
      this.permissionService.loadPermissions(permissions);
    }
  }
}
