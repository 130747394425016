import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {IResponse} from "../../models/response.models";

@Injectable({
  providedIn: 'root'
})
export class AdminAuthService {
  private apiUrl = environment.apiUrl + '/identities';

  constructor(private http: HttpClient) {
  }

  authentication(params: {username: string, password: string}): Observable<IResponse<{ accessToken: string, refreshToken: string, permissions: string[]; }>> {
    return this.http.post<IResponse<{ accessToken: string, refreshToken: string, permissions: string[]; }>>(this.apiUrl + '/authentication', params)
  }

  reset(email: string): Observable<IResponse<string>> {
    return this.http.post<IResponse<string>>(this.apiUrl + '/reset', {email})
  }

  resetConfirm(params: { password: string, confirmPassword: string }, token: string): Observable<IResponse<boolean>> {
    return this.http.post<IResponse<boolean>>(this.apiUrl + '/reset/confirm', params, {
      headers: {
        Authorization: token
      }
    })
  }
  registrationConfirm(token: string): Observable<IResponse<any>> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    });
    return this.http.post<IResponse<any>>(this.apiUrl + '/registration/confirm', {},{headers})
  }

  refresh(params: {accessToken: string, refreshToken: string}): Observable<IResponse<boolean>> {
    return this.http.post<IResponse<boolean>>(this.apiUrl + '/refresh', params)
  }

  logout(): Observable<IResponse<boolean>> {
    return this.http.post<IResponse<boolean>>(this.apiUrl + '/logout', {})
  }
}
