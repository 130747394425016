import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {CommonModule} from "@angular/common";

@Component({
  selector: 'ui-icon',
  templateUrl: './ui-icon.component.html',
  styleUrls: ['./ui-icon.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class UiIconComponent implements AfterViewInit, OnChanges {
  @ViewChild("iconElem", {static: false}) iconElem: ElementRef | undefined;
  @Input() type: 'solid' | 'outline' | 'shared' | 'mini' = 'mini';
  @Input() color: string = '';
  @Input() name: string = '';
  @Input() iconClass: string = '';
  @Input() width: string = '';
  @Input() height: string = '';

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngAfterViewInit() {
    this.getImage()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getImage();
    this.cdr.detectChanges();
  }

  getImage() {
    if (this.iconElem?.nativeElement) {
      this.iconElem.nativeElement.setAttribute('style',
        `-webkit-mask: url('../assets/images/icons/${this.type}/${this.name}.svg') no-repeat 50% 50%;" +
        " mask: url('../assets/images/icons/${this.type}/${this.name}.svg') no-repeat 50% 50%`)
      if (this.color) {
        this.iconElem.nativeElement.style.backgroundColor = `var(--${this.color})`
      }
      if (this.width && this.height) {
        this.iconElem.nativeElement.style.width = this.width + 'px';
        this.iconElem.nativeElement.style.height = this.height + 'px';
      }
    }
  }
}
