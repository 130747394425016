import {Injectable, Injector} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {catchError, debounceTime, first, map, Observable, of, Subject, take, throwError} from 'rxjs';
import {Toaster} from "../../shared/services/toaster.service";
import {ErrorCode} from "../enums/error-code.enum";
import {AdminAuthService} from "../services/admin/admin-auth.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {Router} from "@angular/router";
import * as moment from "moment";

@UntilDestroy()
@Injectable()
export class ApiHttpInterceptor implements HttpInterceptor {
  refreshSubject: Subject<any> = new Subject<any>();

  constructor(private notification: Toaster,
              private injector: Injector,
              private admin: AdminAuthService) {
    this.refreshSubject
      .pipe(
        debounceTime(5000),
        untilDestroyed(this))
      .subscribe(res => {
        this.refreshToken(res);
      })
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.sendRequest(req, next)
  }


  sendRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const meta = JSON.parse(localStorage.getItem('adminMeta'));
    let accessToken = meta ? ('Bearer ' + meta.accessToken) : '';
    if (['/registration/confirm', '/send_new_password/confirm'].some(x => req.url.includes(x))) {
      let signatureToken = localStorage.getItem('signatureToken');
      accessToken = 'Bearer ' + signatureToken;
    }
    let {headers} = req;
    headers.append('Access-Control-Allow-Origin', '*')
    let apiReq = req.clone({
      headers: headers
    });
    if (!(req.url.substring(req.url.length - 8) === '/refresh') && !req.url.includes('/reset/confirm')) {
      apiReq = req.clone({
        headers: req.headers.set('Authorization', accessToken),
        body: this.convertDate(req?.body)
      })
    }
    return next.handle(apiReq).pipe(
      map((res: any) => {
        if (res instanceof HttpResponse) {
          this.errorNotification(res);
          const savePassword = JSON.parse(localStorage.getItem('savePassword') ?? 'false')
          if (res.body.errorCode === ErrorCode.TOKEN_EXPIRED) {
            if (savePassword) {
              this.refreshSubject.next({req: apiReq, next, res});
            } else {
              const router = this.injector.get(Router);
              localStorage.clear();
              router.navigate(['/auth']);
            }
          } else if (res.url.includes('/identities/refresh')) {
            if (res.body.errorCode === ErrorCode.BAD_REQUEST) {
              const router = this.injector.get(Router);
              localStorage.clear();
              router.navigate(['/auth']);
            }
          }
        }
        return res;
      }),
      catchError(x => this.handleAuthError(x)));
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401 || err.status === 403) {
      const router = this.injector.get(Router);
      localStorage.clear();
      router.navigate(['/auth']);
      return of(err.message);
    }
    return throwError(err);
  }

  errorNotification(res: any) {
    if (res.body.errors) {
      Object.keys(res.body.errors).forEach(key => {
        res.body.errors[key].forEach((message: string) => {
          this.notification.toasterSimple(
            "Ошибка!",
            message,
            [],
            {
              icon: 'exclamation-circle',
              iconColor: 'error-400'
            }
          ).onAction
            .pipe(take(1))
            .subscribe();
        })
      })
    }
    if (res.body.errorCode) {
      if (res.body.errorCode !== ErrorCode.TOKEN_EXPIRED) {
        this.notification.toasterSimple(
          "Ошибка!",
          res.body.message,
          [],
          {
            icon: 'exclamation-circle',
            iconColor: 'error-400'
          }
        ).onAction
          .pipe(first())
          .subscribe();
      }
    }
  }

  refreshToken(params: { req: HttpRequest<any>, next: HttpHandler, res: any }): void {
    const meta = JSON.parse(localStorage.getItem('adminMeta'));
    if (meta) {
      this.admin.refresh(meta)
        .pipe(untilDestroyed(this))
        .subscribe(data => {
          if (data.status) {
            localStorage.setItem('adminMeta', JSON.stringify(data.meta));
            location.reload()
          } else {
          }
          this.sendRequest(params.req, params.next);
        })
    }
  }

  private convertDate(body: any) {
    if (body) {
      Object.keys(body).forEach(key => {
        if (body[key] instanceof Date && !isNaN(body[key].valueOf())) {
          body[key] = moment(body[key]).format('YYYY-MM-DD HH:mm:ss')
        }
      });
    }
    return body;
  }
}



