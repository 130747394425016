import { Component } from '@angular/core';
import {Toast, ToastPackage, ToastrService} from "ngx-toastr";
import {UIAction} from "../ui-models";
import {CommonModule} from "@angular/common";
import {UiIconComponent} from "../ui-icon/ui-icon.component";
import {UiLinkComponent} from "../ui-link/ui-link.component";


@Component({
  selector: 'simple-toaster',
  templateUrl: './toaster-simple.component.html',
  styleUrls: ['./toaster-simple.component.scss'],
  preserveWhitespaces: false,
  standalone: true,
  imports: [CommonModule, UiIconComponent, UiLinkComponent]
})
export class ToasterSimpleComponent extends Toast {
  constructor(
    toastrService: ToastrService, toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
  }

  action(event: Event, action: UIAction) {
    this.toastPackage.triggerAction(action);
    return false;
  }

  close(): void {
  }
}
