<div class="toaster-container animate__animated animate__fadeInRightBig">
  <div class="toaster-icon">
    <img *ngIf="options?.payload?.data.img" [src]="options?.payload?.data.img" alt="">
  </div>
  <div class="content">
    <h1 class="title" [innerHTML]="title"></h1>
    <p class="description" [innerHTML]="message"></p>
  </div>
  <div class="flex flex-col actions">
    <div class="action"
         *ngFor="let btn of options?.payload?.buttons">
      <ui-link [size]="'base'" [color]="btn['class']"
               (click)="action($event, btn.action)"
      >{{btn.text}}</ui-link>
    </div>
  </div>
</div>
