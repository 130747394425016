import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from "ngx-toastr";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ApiHttpInterceptor } from "./core/interceptor/api.interceptor";
import { DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule } from "@angular/material/core";
import { CustomDateAdapter } from "./shared/services/custom.date.adapter";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { registerLocaleData } from "@angular/common";
import localeRu from '@angular/common/locales/ru';
import {provideNgxMask} from "ngx-mask";
import {NgApexchartsModule} from "ng-apexcharts";
import {NgxPermissionsModule} from 'ngx-permissions';
import {register} from 'swiper/element/bundle';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS} from "@angular/material-moment-adapter";

registerLocaleData(localeRu);
register();

const formFieldOptions: MatFormFieldDefaultOptions = {
  appearance: 'outline',
  floatLabel: 'always'
};


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    MatDatepickerModule,
    NgApexchartsModule,
    MatNativeDateModule,
    NgxPermissionsModule.forRoot(),
  ],
  providers: [
    {provide: DateAdapter, useClass: CustomDateAdapter},
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true }},
    { provide: MAT_DATE_LOCALE, useValue: 'ru-RU' },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: formFieldOptions
    },
    {
      provide: LOCALE_ID,
      useValue: 'ru-RU'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiHttpInterceptor,
      multi: true,
    },
    MatNativeDateModule,
    provideNgxMask(),
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
