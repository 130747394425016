import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import {IResponse} from "../../models/response.models";
import {IAdminInfo, IProfile, ProfileUpdateRequestParams} from "../../models/profile.models";
import {
  CaptureBlockResponse,
  IColor,
  IMarketingInfo,
  IMarketingInfoBlockResponse,
  IMarketingInfoDetail
} from "../../models/marketingInfo.models";
import { IAdminUserService } from '../../models/admin-user-service.models';

@Injectable({
  providedIn: 'root'
})
export class AdminUsersService {
  public employee$ = new BehaviorSubject<IProfile | null>(null);

  private apiUrl = environment.apiUrl + '/admin_users';
  private userServiceApiUrl = environment.apiUrl + '/admin_user_services';

  constructor(private http: HttpClient) {
  }

  getUsers(params: {Filters: string, Sorts: string, Page: string, PageSize: string}): Observable<IResponse<IProfile[]>> {
    return this.http.get<IResponse<IProfile[]>>(this.apiUrl, {params})
  }

  getUserById(id: string): Observable<IResponse<IProfile>> {
    return this.http.get<IResponse<IProfile>>(this.apiUrl + '/' + id)
  }
  getUserInfo(id: string): Observable<IResponse<IProfile>> {
    return this.http.get<IResponse<IProfile>>(`${this.apiUrl}/${id}/info`)
  }


  getProfile(): Observable<IResponse<IProfile>> {
    return this.http.get<IResponse<IProfile>>(this.apiUrl + '/profile')
  }
  getAdminInfo(): Observable<IResponse<IAdminInfo>> {
    return this.http.get<IResponse<IAdminInfo>>(this.apiUrl + '/info')
  }

  activeProfile(id: string): Observable<IResponse<IProfile>> {
    return this.http.post<IResponse<IProfile>>(`${this.apiUrl}/${id}/profile/active`, {});
  }

  deactiveProfile(id: string): Observable<IResponse<IProfile>> {
    return this.http.post<IResponse<IProfile>>(`${this.apiUrl}/${id}/profile/deactive`, {});
  }

  activeService(id: string): Observable<IResponse<IProfile>> {
    return this.http.post<IResponse<IProfile>>(`${this.apiUrl}/${id}/service/active`, {});
  }

  deactiveService(id: string): Observable<IResponse<IProfile>> {
    return this.http.post<IResponse<IProfile>>(`${this.apiUrl}/${id}/service/deactive`, {});
  }

  getMarketingInfo(id: string): Observable<IResponse<IMarketingInfo>> {
    return this.http.get<IResponse<IMarketingInfo>>(this.apiUrl + '/' + id + '/marketing_info')
  }

  updateMarketingInfo(data: IMarketingInfo): Observable<IResponse<IMarketingInfoDetail>> {
    return this.http.post<IResponse<IMarketingInfoDetail>>(this.apiUrl + '/update_marketing_info', data)
  }

  updateExperiences(data: IMarketingInfoBlockResponse): Observable<IResponse<boolean>> {
    return this.http.post<IResponse<boolean>>(this.apiUrl + '/update_experiences', data)
  }

  updateEducation(data: IMarketingInfoBlockResponse): Observable<IResponse<boolean>> {
    return this.http.post<IResponse<boolean>>(this.apiUrl + '/update_educations', data)
  }

  updateProblemsArea(data: IMarketingInfoBlockResponse): Observable<IResponse<boolean>> {
    return this.http.post<IResponse<boolean>>(this.apiUrl + '/update_problems_area', data)
  }

  updateWorks(data: IMarketingInfoBlockResponse): Observable<IResponse<boolean>> {
    return this.http.post<IResponse<boolean>>(this.apiUrl + '/update_works', data)
  }

  updateExceptionFactors(data: IMarketingInfoBlockResponse): Observable<IResponse<boolean>> {
    return this.http.post<IResponse<boolean>>(this.apiUrl + '/update_exception_factors', data)
  }
  updateAdvantages(data: IMarketingInfoBlockResponse): Observable<IResponse<boolean>> {
    return this.http.post<IResponse<boolean>>(this.apiUrl + '/update_advantages', data)
  }

  uploadCaptureBlock(data: CaptureBlockResponse): Observable<IResponse<boolean>> {
    return this.http.post<IResponse<boolean>>(this.apiUrl + '/update_capture_block', data)
  }

  uploadMarketingGraphic(data: FormData): Observable<IResponse<{fileId: string}>> {
    return this.http.post<IResponse<{fileId: string}>>(`${this.apiUrl}/upload_marketing_graphic`, data);
  }

  getMarketingInfoDetail(id: string): Observable<IResponse<IMarketingInfoDetail>> {
    return this.http.get<IResponse<IMarketingInfoDetail>>(this.apiUrl + '/' + id + '/marketing_info/detail')
  }

  getColorPalettes(): Observable<IResponse<IColor[]>> {
    return this.http.get<IResponse<IColor[]>>(this.apiUrl + '/color_palettes')
  }

  updateProfile(params: ProfileUpdateRequestParams): Observable<IResponse<boolean>> {
    return this.http.post<IResponse<boolean>>(this.apiUrl + '/update', params)
  }

  uploadAvatar(image: File): Observable<any> {
    const formData = new FormData();
    formData.append('image', image, image.name);
    return this.http.post<IResponse<{ "fileId": string }>>(this.apiUrl + '/upload_avatar', formData,
      {
        reportProgress: true,
        observe: 'events'
      })
  }

  changePassword(params: {
    currentPassword: string,
    newPassword: string,
    confirmPassword: string
  }): Observable<IResponse<boolean>> {
    return this.http.post<IResponse<boolean>>(this.apiUrl + '/change_password', params)
  }

  changeEmail(params: {
    id: string,
    email: string
  }): Observable<IResponse<boolean>> {
    return this.http.post<IResponse<boolean>>(this.apiUrl + '/change_email', params)
  }

  updateNotificationSettings(params: { emailNotification: boolean, pushNotification: boolean }): Observable<IResponse<boolean>> {
    return this.http.post<IResponse<boolean>>(this.apiUrl + '/update_notification_settings', params)
  }

  getUserServices(id: string): Observable<IResponse<IAdminUserService>> {
    return this.http.get<IResponse<IAdminUserService>>(`${this.userServiceApiUrl}/${id}/settings`);
  }

  saveUserServices(data: IAdminUserService): Observable<IResponse<IAdminUserService>> {
    return this.http.post<IResponse<IAdminUserService>>(`${this.userServiceApiUrl}/settings/update`, data);
  }
}
