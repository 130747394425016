<div class="toaster-container animate__animated animate__fadeInRightBig">
  <ui-icon class="toaster-icon" *ngIf="options.payload.data.icon"
           [color]="options.payload.data.iconColor" [type]="'outline'" [name]="options.payload.data.icon"></ui-icon>
  <div class="content">
    <h1 class="title" [innerHTML]="title"></h1>
    <p class="description" [innerHTML]="message"></p>
    <div class="flex items-center gap-[24px]">
      <ui-link [size]="'base'" [color]="btn['class']" *ngFor="let btn of options?.payload?.buttons"
               (click)="action($event, btn.action)"
      >{{btn.text}}</ui-link>
    </div>
  </div>
  <ui-icon [name]="'x-mark'" class="toast-close-button" (click)="close()"></ui-icon>
</div>
